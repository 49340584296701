import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from 'axios'; 
import { BASE_URL } from "../utilities/URL";
import { info_toaster } from "../utilities/Toaster";

const Success = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const userId = searchParams.get("userId");
    const price = searchParams.get("price");
    const packageType = searchParams.get("packageType");
    const paymentMode = searchParams.get("paymentMethod");

    if (userId && price && packageType) {
      axios.post(`${BASE_URL}earning/v1/paymentStatusUpdateStripe`, {
        userId,
        price,
        packageType,
        paymentMode
      })
      .then(response => {
        console.log(response?.data)
        if(response?.data?.status === '1'){
          // success_toaster(response?.data?.message)
          info_toaster(response?.data?.message)
        }
        else{
          info_toaster(response?.data?.message)
        }
      })
      .catch(error => {
        console.error('Error updating payment status:', error);
        info_toaster(error)
      });
    }
  }, [searchParams]);

  return (
    <>
      <Navbar navfixed={"nav-fixed"} />
      <div className="container" style={{ marginTop: "140px" }}>
        <div>
          <h1>Payment Successful!</h1>
          <p>Thank you for purchasing a package.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Success;
