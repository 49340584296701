import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

export default function AboutUs() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Navbar navfixed={"nav-fixed"} />
      <div className="container" style={{ marginTop: "140px" }}>
        <h1>
          About <b>Earn Crypto</b>
        </h1>
        <p>
          Welcome to <b>Earn Crypto</b>, your go-to resource for information on
          Pay-to-Click (PTC) opportunities. We're dedicated to providing you
          with the best knowledge and updates on earning through PTC platforms.
        </p>
        <p>
          We hope you find all the information on <b>Earn Crypto</b> valuable,
          as we love sharing these insights with you.
        </p>
        <p>
          If you have any questions or need more information about our site,
          please feel free to contact us via email at{" "}
          <a href="mailto:info@earncrypto.vip">
            <b>info@earncrypto.vip</b>
          </a>
          .
        </p>
      </div>

      <Footer />
    </>
  )
}
