import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

export default function Terms() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Navbar navfixed={"nav-fixed"} />
      <div className="container" style={{ marginTop: "140px" }}>
        <h1>Terms and Conditions</h1>

        <h4>1. Introduction</h4>
        <p>
          Welcome to <a href="http://earncrypto.vip/">Earn Crypto</a> (the
          "Site"). These Terms and Conditions ("Terms") govern your use of our
          Site and services. By accessing or using our Site, you agree to comply
          with and be bound by these Terms. If you do not agree with these
          Terms, please do not use our Site.
        </p>

        <h4>2. Service Description</h4>
        <p>
          Earn Crypto provides a platform where users can select packages and
          earn on a daily basis by clicking on ads. Each ad click represents a
          trade, and when you click on an ad, our admin team performs a trade on
          your behalf.
        </p>

        <h4>3. User Responsibilities</h4>
        <ul>
          <li>
            <strong>Eligibility</strong>: You must be at least 18 years old or
            of legal age in your jurisdiction to use our Site and services.
          </li>
          <li>
            <strong>Account Information</strong>: You are responsible for
            maintaining the confidentiality of your account information and for
            all activities that occur under your account.
          </li>
          <li>
            <strong>Prohibited Activities</strong>: You agree not to engage in
            any fraudulent, abusive, or illegal activity related to the use of
            our Site. This includes, but is not limited to, manipulating ad
            clicks or participating in schemes designed to defraud our platform.
          </li>
        </ul>

        <h4>4. Packages and Payments</h4>
        <ul>
          <li>
            <strong>Selection</strong>: Users can select from various packages
            offered on the Site. Each package may have different features and
            benefits.
          </li>
          <li>
            <strong>Payments</strong>: Payments for packages must be made as per
            the instructions provided on our Site. We do not guarantee specific
            earnings and are not responsible for any losses or discrepancies.
          </li>
        </ul>

        <h4>5. Ads and Trades</h4>
        <ul>
          <li>
            <strong>Ad Clicking</strong>: Clicking on ads on our Site is a part
            of the earning process. By clicking on ads, you agree that the admin
            team will perform trades on your behalf as described.
          </li>
          <li>
            <strong>Trades</strong>: All trades conducted on your behalf are
            performed with the intent to provide you with potential earnings.
            However, we do not guarantee specific results or profits from these
            trades.
          </li>
        </ul>

        <h4>6. Privacy Policy</h4>
        <p>
          Your use of our Site is also governed by our{" "}
          <a href="#privacy-policy">Privacy Policy</a>, which outlines how we
          collect, use, and protect your personal information.
        </p>

        <h4>7. Termination</h4>
        <p>
          We reserve the right to terminate or suspend your account and access
          to our Site at our sole discretion, including if you violate these
          Terms.
        </p>

        <h4>8. Disclaimers and Limitation of Liability</h4>
        <ul>
          <li>
            <strong>No Guarantees</strong>: We do not guarantee specific
            earnings, results, or the accuracy of information provided on the
            Site.
          </li>
          <li>
            <strong>Liability</strong>: To the fullest extent permitted by law,
            we are not liable for any direct, indirect, incidental, or
            consequential damages arising from your use of our Site or services.
          </li>
        </ul>

        <h4>9. Changes to Terms</h4>
        <p>
          We may update these Terms from time to time. Any changes will be
          posted on this page, and your continued use of the Site constitutes
          acceptance of the updated Terms.
        </p>

        <h4>10. Governing Law</h4>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of [Your Jurisdiction]. Any disputes arising from these Terms or your
          use of our Site will be subject to the exclusive jurisdiction of the
          courts in [Your Jurisdiction].
        </p>

        <h4>11. Contact Information</h4>
        <p>
          For any questions or concerns regarding these Terms, please contact us
          at:
        </p>
        <p>
          <strong>Email</strong>:{" "}
          <a href="mailto:info@earncrypto.vip">info@earncrypto.vip</a>
        </p>
      </div>
      <Footer />
    </>
  )
}
