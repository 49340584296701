import React, { useEffect } from "react";

const AdComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      atOptions = {
        'key' : '784ec47d8148df7ef22bcbe9a9652ec2',
        'format' : 'iframe',
        'height' : 250,
        'width' : 300,
        'params' : {}
      };
    `;

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "//www.topcreativeformat.com/784ec47d8148df7ef22bcbe9a9652ec2/invoke.js";

    document.getElementById("ad-container").appendChild(script);
    document.getElementById("ad-container").appendChild(script2);
  }, []);

  return <div id="ad-container" className=""></div>;
};

export default AdComponent;
