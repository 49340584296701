import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

export default function Privacy() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Navbar navfixed={"nav-fixed"} />
      <div className="container" style={{ marginTop: "140px" }}>
        <h1>Privacy Policy</h1>

        <h2>1. Introduction</h2>
        <p>
          Welcome to <a href="http://earncrypto.vip/">Earn Crypto</a> (the
          "Site"). We value your privacy and are committed to protecting your
          personal information. This Privacy Policy explains how we collect,
          use, and safeguard your information when you visit our Site.
        </p>

        <h2>2. Information We Collect</h2>

        <h3>2.1 Personal Information</h3>
        <p>
          We may collect personal information that you voluntarily provide to
          us, such as:
        </p>
        <ul>
          <li>
            <strong>Contact Information</strong>: Name, email address, and other
            contact details.
          </li>
          <li>
            <strong>Account Information</strong>: Username, password, and other
            account-related data.
          </li>
        </ul>

        <h3>2.2 Usage Data</h3>
        <p>
          We may collect information about your interactions with our Site,
          including:
        </p>
        <ul>
          <li>
            <strong>Log Data</strong>: IP address, browser type, pages visited,
            and time spent on our Site.
          </li>
          <li>
            <strong>Cookies</strong>: We use cookies to enhance your experience
            and gather information about how you use our Site.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li>
            <strong>To Provide and Improve Services</strong>: To operate and
            maintain our Site, process transactions, and improve user
            experience.
          </li>
          <li>
            <strong>To Communicate with You</strong>: To respond to your
            inquiries, send updates, and provide customer support.
          </li>
          <li>
            <strong>To Analyze Usage</strong>: To analyze usage patterns and
            improve our Site’s functionality and content.
          </li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties except in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>With Your Consent</strong>: We may share your information if
            you consent to it.
          </li>
          <li>
            <strong>Service Providers</strong>: We may share information with
            third-party service providers who assist us in operating our Site
            and delivering services.
          </li>
          <li>
            <strong>Legal Requirements</strong>: We may disclose information if
            required to do so by law or to protect our rights and safety.
          </li>
        </ul>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our Site uses cookies and similar tracking technologies to enhance
          your experience and gather information about usage. You can set your
          browser to refuse cookies or to notify you when cookies are being
          sent. However, some parts of our Site may not function properly if you
          disable cookies.
        </p>

        <h2>6. Data Security</h2>
        <p>
          We implement reasonable measures to protect your personal information
          from unauthorized access, disclosure, alteration, and destruction.
          However, no security system is impenetrable, and we cannot guarantee
          the absolute security of your information.
        </p>

        <h2>7. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>
            <strong>Access</strong>: Request a copy of the personal information
            we hold about you.
          </li>
          <li>
            <strong>Correction</strong>: Request correction of any inaccuracies
            in your personal information.
          </li>
          <li>
            <strong>Deletion</strong>: Request deletion of your personal
            information, subject to legal requirements.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:info@earncrypto.vip">info@earncrypto.vip</a>.
        </p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and your continued use of the Site constitutes
          acceptance of the updated policy.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          <strong>Email</strong>:{" "}
          <a href="mailto:info@earncrypto.vip">info@earncrypto.vip</a>
        </p>
      </div>
      <Footer />
    </>
  )
}
