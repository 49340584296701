import React, { useEffect, useState, useCallback } from "react"
import Footer from "../components/Footer"
import { useLocation, useNavigate } from "react-router-dom"
import GetAPI from "../utilities/GetAPI"
import { PostAPI } from "../utilities/PostAPI"
import { info_toaster } from "../utilities/Toaster"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react"
import AdComponent from "../components/AdComponent"
import Navbar from "../components/Navbar"
import { BASE_URL } from "../utilities/URL"

export default function Earn() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [countdown, setCountdown] = useState(30)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const { data, reFetch } = GetAPI(
    `earning/v1/getUserAds/${parseInt(localStorage.getItem("userId") || "0")}`
  )

  const getLocalAdvertisement = GetAPI("admin/v1/getLocalAdvertisement")
  const advertisements = getLocalAdvertisement?.data?.data?.data || []
  const [currentAdIndex, setCurrentAdIndex] = useState(0)

  useEffect(() => {
    if (advertisements.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentAdIndex((prevIndex) =>
          prevIndex === advertisements.length - 1 ? 0 : prevIndex + 1
        )
      }, 30000) // 30000 ms = 30 seconds

      return () => clearInterval(intervalId) // Cleanup on unmount
    }
  }, [advertisements])

  const currentAd = advertisements[currentAdIndex]

  const watchAdFunc = useCallback(
    async (id) => {
      try {
        const res = await PostAPI("earning/v1/watchAd", {
          userId: localStorage.getItem("userId"),
          adId: id,
        })
        info_toaster(res?.data?.message)
        if (res?.data?.status === "1") {
          reFetch()
        }
      } catch (error) {
        console.error("Error watching ad:", error)
        info_toaster("An error occurred while watching the ad")
      }
    },
    [reFetch]
  )

  useEffect(() => {
    let timer
    if (isOpen && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [isOpen, countdown])

  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.innerHTML = `
      atOptions = {
        'key' : '784ec47d8148df7ef22bcbe9a9652ec2',
        'format' : 'iframe',
        'height' : 250,
        'width' : 300,
        'params' : {}
      };
    `
    const script2 = document.createElement("script")
    script2.type = "text/javascript"
    script2.src =
      "//www.topcreativeformat.com/784ec47d8148df7ef22bcbe9a9652ec2/invoke.js"

    document.getElementById("ad-container")?.appendChild(script)
    document.getElementById("ad-container")?.appendChild(script2)
  }, [])

  return (
    <>
      <Navbar navfixed={"nav-fixed"} />
      <div
        className="banner"
        id="home"
        style={{ backgroundImage: "url(/img/earn-bg.jpeg)" }}
      ></div>

      <section className="container my-5">
        <div>
          {data?.data?.userData?.paymentStatus ? (
            <>
              {data?.data?.addData?.length > 0 && (
                <h2 className="mb-2">
                  You can <strong style={{ color: "red" }}>trade</strong>{" "}
                  Following
                </h2>
              )}
              <div className="d-flex">
                <div style={{ width: "70%" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "16px",
                    }}
                  >
                    {data?.data?.addData?.length > 0 ? (
                      data.data.addData.map((item, index) => (
                        <>
                          <div
                            key={index}
                            className="flex flex-justify-between flex-align-center bg-white porto"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="mt-3">
                              <div className="flex flex-align-center flex-justify-between mb-2">
                                <p className="text-xl mr-4">
                                  <b>{item.title}</b>
                                </p>
                              </div>
                            </div>
                            <div className="m-auto">
                              <button
                                onClick={onOpen}
                                className="btn btn-primary"
                              >
                                Trade
                              </button>
                            </div>
                          </div>

                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>
                                Calculating Trade and Analysis
                              </ModalHeader>
                              <ModalBody>
                                {countdown > 0 ? (
                                  <>
                                    <h3>
                                      Please Wait for {countdown} seconds...
                                    </h3>
                                  </>
                                ) : (
                                  <div className="mx-auto text-center">
                                    <button
                                      onClick={() => {
                                        watchAdFunc(item?.id)
                                        onClose()
                                        setCountdown(10)
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Trade Now
                                    </button>
                                  </div>
                                )}

                                <div
                                  className="d-flex align-items-center"
                                  style={{ rowGap: "5px", columnGap: "5px" }}
                                >
                                  <div className="my-3 w-50">
                                    <img
                                      src="https://cdn.creative-bars1.com/sb/notifications/shop/daraz/custom_banner/12/img/bg-old.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <h3 className="w-50">
                                    <a
                                      href={
                                        getLocalAdvertisement?.data?.data
                                          ?.data?.[0]?.link
                                      }
                                      target="_blank"
                                    >
                                      {
                                        getLocalAdvertisement?.data?.data
                                          ?.data?.[0]?.title
                                      }
                                    </a>
                                    <img
                                      className="img-fluid"
                                      src={`${BASE_URL}${getLocalAdvertisement?.data?.data?.data?.[0]?.image}`}
                                      alt=""
                                    />
                                    <video
                                      className="form-control img-fluid"
                                      src={`${BASE_URL}${getLocalAdvertisement?.data?.data?.data?.[0]?.image}`}
                                    ></video>
                                  </h3>
                                </div>
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </>
                      ))
                    ) : (
                      <h2 className="mb-2" style={{ whiteSpace: "nowrap" }}>
                        You have successfully{" "}
                        <strong style={{ color: "red" }}>traded</strong> today
                      </h2>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    {advertisements.length > 0 && (
                      <h3>
                        <a
                          href={currentAd?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {currentAd?.title}
                        </a>
                        <img
                          className="img-fluid"
                          src={`${BASE_URL}${currentAd?.image}`}
                          alt={currentAd?.title || "Advertisement"}
                        />
                        <video
                          className="form-control img-fluid"
                          src={`${BASE_URL}${currentAd?.video}`}
                          controls
                        ></video>
                      </h3>
                    )}
                  </div>
                  <div id="ad-container" className=""></div>
                  <AdComponent />
                </div>
              </div>
            </>
          ) : (
            <>
              {data.status !== "0" ? (
                <>
                  <h3 className="mb-2">
                    Your account is still
                    <strong style={{ color: "red" }}> pending </strong>from
                    admin side
                  </h3>
                  <p>Verification may take up to 24 hours</p>
                </>
              ) : (
                <h3 className="mb-2">
                  Please Buy the
                  <strong style={{ color: "red" }}> Package </strong> first
                </h3>
              )}
            </>
          )}
        </div>
      </section>

      <Footer />
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up"></i>
      </div>
    </>
  )
}
