import "./App.css"
import Home from "./pages/Home"
import ErrorPage from "./errors/error-page"
import { ToastContainer } from "react-toastify"
import { ChakraProvider } from "@chakra-ui/react"
import Login from "./pages/Login"
import Signup from "./pages/Signup"
import Earn from "./pages/Earn"
import Profile from "./pages/Profile"
import TradingViewWidget from "../src/pages/test"
import { PrimeReactProvider } from "primereact/api"
import "primereact/resources/themes/lara-light-cyan/theme.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Card from "./pages/Card"
import Package from "./components/Package"
import AboutUs from "./pages/AboutUs"
import Terms from "./pages/Terms"
import Privacy from "./pages/Privacy"
import Sucess from "./pages/Sucess"

function App() {
  return (
    <div>
      <ToastContainer />
      <PrimeReactProvider>
        <ChakraProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/" element={<Home />} />
              <Route path="/earn" element={<Earn />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/packages" element={<Package />} />
              <Route path="/*" element={<ErrorPage />} />
              <Route path="/test" element={<TradingViewWidget />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/success" element={<Sucess />} />
              <Route path="/cancel" element={<Privacy />} />
            </Routes>
          </Router>
        </ChakraProvider>
      </PrimeReactProvider>
    </div>
  )
}

export default App
